/* ===== IMPORTS ===== */
import styles from "./Icon.module.css";

function AdminIcon({ title }) {
  /* ===== ADMIN ICON COMPONENT ===== */
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={ styles.icon }
      width="30"
      height="30"
      viewBox="0 0 30 30"
    >
      <path
        stroke="none"
        d="M2.363 5.746c-.346.903-.15 2.12.436 2.707 1.524 1.524 4.41.13 4.012-1.935-.443-2.302-3.647-2.858-4.448-.772m10 .004c-.347.9-.168 2.099.397 2.664 1.63 1.63 4.74.53 4.74-1.675 0-2.543-4.229-3.358-5.138-.99m11.001-.003c-.346.903-.15 2.12.436 2.707 1.524 1.524 4.41.13 4.012-1.935-.443-2.302-3.647-2.858-4.448-.772M13 11.753c-1.408 1.696-5.563 2.016-6.5.5-1.379-2.231-2.602-.774-2.31 2.75l.31 3.75h21l.31-3.75c.292-3.524-.93-4.981-2.31-2.75-.937 1.516-5.092 1.196-6.5-.5-.685-.825-1.585-1.5-2-1.5-.415 0-1.315.675-2 1.5m-9 10.3c0 2.337 2.966 3.2 11 3.2 8.034 0 11-.863 11-3.2 0-.44-4.95-.8-11-.8s-11 .36-11 .8"
      ></path>
      <title>{ title }</title>
    </svg>
  );
}

/* ===== EXPORTS ===== */
export default AdminIcon;
function Youtube () {
  /* ===== YOUTUBE COMPONENT ===== */
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="159" height="110" viewBox="0 0 159 110">
      <path
        fill="red"
        d="M154 17.5c-1.82-6.73-7.07-12-13.8-13.8-9.04-3.49-96.6-5.2-122 .1-6.73 1.82-12 7.07-13.8 13.8C.32 35.5.01 74.2 4.5 92.5c1.82 6.73 7.07 12 13.8 13.8 17.9 4.12 103 4.7 122 0 6.73-1.82 12-7.07 13.8-13.8 4.35-19.5 4.66-55.8-.1-75z"
      ></path>
      <path fill="#fff" d="M105 55L64.2 31.6v46.8z"></path>
    </svg>
  );
};

/* ===== EXPORTS ===== */
export default Youtube;
function Logo() {
  /* ===== LOGO COMPONENT ===== */
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="465.571"
      height="211.658"
      version="1.1"
      viewBox="0 0 123.182 56.001"
      xmlSpace="preserve"
    >
      <g transform="translate(311.034 -7.19)">
        <g fill="#e2bbec" fillOpacity="1">
          <path
            strokeLinecap="square"
            strokeMiterlimit="9"
            strokeWidth="1.5"
            d="M-297.029 10.936c-13.393 7.733-17.981 24.858-10.25 38.25 7.733 13.393 24.858 17.982 38.251 10.25a28.001 28.001 0 0013.996-23.716c-3.068 3.12-6.31 6.034-9.744 8.693-1.441 3.29-4.525 6.461-8.027 8.484-4.161 2.402-8.664 3.024-11.257 2.668l-.065.027a20.45 20.45 0 01-10.995-3.93c19.341-6.036 32.795-17.028 45.434-32.76a219.186 219.186 0 003.476-4.414c-.075-.143-.15-.286-.227-.428a42.255 42.255 0 00-3.31-4.898 124.433 124.433 0 01-9.747 10.885c-8.08-12.561-24.596-16.57-37.535-9.11zm3.775 6.54c9.782-5.648 22.289-2.296 27.936 7.485.057.107.114.214.169.322-9.505 8.225-19.86 14.405-30.914 18.78a110.885 110.885 0 01-4.477 1.66 19.991 19.991 0 01-.199-.312c-5.647-9.781-2.295-22.288 7.485-27.935z"
            paintOrder="stroke fill markers"
          ></path>
          <g
            strokeWidth="0.717"
            fontFamily="Loew"
            fontSize="28.675"
            letterSpacing="-0.212"
            transform="translate(-63.762 -20.493) scale(.78735)"
          >
            <path
              style={{ lineHeight: "1.25", InkscapeFontSpecification: "Loew" }}
              d="M-235.129 72.544v20.073h16.603V87.77h-11.212v-3.01h10.266v-4.588h-10.266V77.39h11.04v-4.846zm18.291 0v20.073h15.227V87.77h-9.836V72.544zm23.836 0v4.617h5.82v15.456h5.42V77.16h5.822v-4.617zm18.795 0v20.073h16.603V87.77h-11.212v-3.01h10.266v-4.588h-10.266V77.39h11.04v-4.846zm-25.782.029v20.044h5.391V72.573z"
            ></path>
            <path
              style={{ lineHeight: "1.25", InkscapeFontSpecification: "Loew" }}
              d="M-219.402 48.23c-4.674 0-8.344 2.066-8.344 6.567 0 3.9 3.269 4.961 7.828 6.08 3.298.831 3.871.946 3.871 2.093 0 .917-.975 1.319-2.093 1.319-2.552 0-5.448-.746-8.058-2.208l-2.409 4.014c2.753 1.836 6.137 2.81 9.865 2.81 5.161 0 8.401-2.351 8.401-6.624 0-4.1-3.354-5.247-8.057-6.509-3.126-.774-3.499-1.032-3.499-1.663 0-.917.89-1.319 1.922-1.319 1.95 0 4.674.688 6.968 1.864l2.265-4.158c-2.352-1.434-5.449-2.237-8.66-2.237zm9.912.288V68.59h5.305V55.744l6.05 10.208h1.835l6.051-10.208V68.59h5.334V48.518h-7.083l-5.219 8.63-5.19-8.63zm27.155.028V68.59h11.298c5.22 0 6.911-2.724 6.911-5.477 0-2.093-.975-4.186-3.785-5.047 1.892-.774 2.954-2.265 2.954-4.444 0-3.355-2.438-5.076-6.567-5.076zm5.391 4.387h3.843c1.606 0 2.494.631 2.494 1.864 0 1.205-.917 1.835-2.494 1.835h-3.843zm0 7.284h4.56c1.978 0 2.609 1.004 2.609 2.007 0 1.004-.63 1.979-2.61 1.979h-4.559z"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};

/* ===== EXPORTS ===== */
export default Logo;